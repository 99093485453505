.container {
  display: flex;
}

.attachments {
  display: flex;
}

.attachmentContainer {
  width: 102px;
  height: 102px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  border: 1px solid rgba(0, 0, 0, 0.2);
}

.attachment {
  width: 46px;
  height: 46px;
  object-fit: contain;
}

.attachmentName {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btnsContainer {
  width: 102px;
  height: 102px;
  display: none;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.8);
  cursor: default;
}

.draggable {
  cursor: move;
}

.attachmentContainer:hover > .btnsContainer {
  display: flex;
}

.deleteBtn, .editBtn {
  width: 24px;
  height: 24px;

  background: none;
  border: none;

  cursor: pointer;
}

.deleteIcon, .editIcon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dropArea {
  width: 10px;
  height: 102px;
  background: grey;
}
