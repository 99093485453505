.fileName {
  height: 32px;
  line-height: 32px;
  width: 100px;
  align-items: center;
  padding: 0 6px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background: #ffc4c4;
    border: 1px solid #ff5555;
  }
}

.previews {
  display: flex;
}

.previewContainer {
  width: 102px;
  height: 102px;
  position: relative;
  border: 1px solid rgba(0,0,0,0.2);
}

.previewImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.previewVideoIcon {
  width: 32px;
  height: 32px;
  padding: 4px;
  object-fit: contain;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: white;
  border-radius: 4px;
}

.btnsContainer {
  width: 102px;
  height: 102px;
  display: none;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.8);
  cursor: default;
}

.draggable {
  cursor: move;
}

.previewContainer:hover > .btnsContainer {
  display: flex;
}

.deleteBtn, .editBtn {
  width: 24px;
  height: 24px;

  background: none;
  border: none;

  cursor: pointer;
}

.deleteIcon, .editIcon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dropArea {
  width: 10px;
  height: 102px;
  background: grey;
}
