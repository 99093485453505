.main {
  display: flex;
  padding: 5px;
  /*background: grey;*/
  height: calc(100vh - 64px);
  column-gap: 5px;
  overflow-x: auto;
  flex-wrap: nowrap;
}
.column {
  position: relative;
  max-height: 100%;
  padding: 5px;
  background: #ebecf0;
  flex-basis: 272px;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-y: auto;
  border-radius: 3px;
  /*min-width: 272px;*/
  height: fit-content;
  transition: height 0.7s linear;
  min-height: 200px;
}

.columnHover {
  background: #d6d7da;
}

/*.column: {*/
/*}*/

.columnHead {
  padding: 10px 8px;
  padding-top: 0;
}
.columnTitle {
  color: #172b4d;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 28px;
}

.columnBody {
  padding: 0 8px;
}

.card {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 0 #091e4240;
  cursor: pointer;
  display: block;
  margin-bottom: 8px;
  max-width: 300px;
  min-height: 20px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  padding: 10px 8px;
}
.unsubscribedCard {
  border: rgba(255, 77, 79, 0.5) 1px solid;
}
.unsubscribed {
  background-color: #ff4d4f;
  width: max-content;
  padding: 0px 4px;
  border-radius: 10px;
}
.unsubscribed > p {
  font-weight: 700;
  font-size: 10px;
  margin: 0;
  color: #fff;
}
.card:last-child {
  margin-bottom: 0;
}

.cardHead {
  position: relative;
}

.delete {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 20px;
  opacity: 0;
}

.cardTitle {
  color: #172b4d;
  font-size: 14px;
  font-weight: 600;
}

.card:hover .delete {
  opacity: 1;
}

.delete:hover {
  color: #ff4d4f;
}

.cardBody {
  padding: 0px 0px 2px;
}

.cardText {
  color: #172b4d;
}

.cardText:last-of-type {
  margin-bottom: 0;
}

.cardContextItemsContainer {
  padding: 0px !important;
}
.cardContextItemContainer {
  background: white;
}

.cardContextItem {
  min-width: 180px;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.cardContextItemContainer:hover {
  background-color: rgb(226, 226, 226);
}
.cardContextItem p {
  margin-bottom: 0;
  line-height: normal;
}

.cardContextItemIcon {
  font-size: 18px;
  margin-right: 10px;
}
