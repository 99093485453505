.form {
  margin-bottom: 20px;
}
.modalForm {
  margin-bottom: 0;
  grid-template-columns: 1fr !important;
  gap: 0 !important;
}
.formItem {
  margin: 0 0 8px;
}
.box {
  display: flex;
  flex-direction: column;
}
.label {
  margin: 0;
  min-width: max-content;
}

.input {
  height: 32px;
  font-size: 14px;
}

.categoriesSelect {
  width: 100% !important;
}
.categoriesAndProducts {
  display: flex;
  gap: 10px;
  grid-gap: 10px;
}
