.container {
	max-width: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	margin: 40px auto 0;
}

.form {
	width: 100%;
}

.form__item {
	display: flex;
	justify-content: center;
	align-items: center;
}

.login {
	width: 100%;
	margin-bottom: 10px;
}

.forgot {
	float: right;
}
