.container {
  display: flex;
  //height: 102px;
  //height: 118px;
}

.images {
  display: flex;
}

.imageContainer {
  width: 102px;
  min-height: 102px;
  max-height: 102px;
  position: relative;
  border: 1px solid rgba(0,0,0,0.2);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.btnsContainer {
  width: 102px;
  height: 102px;
  display: none;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.8);
  cursor: default;
}

.draggable {
  cursor: move;
}

.imageContainer:hover > .btnsContainer {
  display: flex;
}

.deleteBtn, .editBtn {
  width: 24px;
  height: 24px;

  background: none;
  border: none;

  cursor: pointer;
}

.deleteIcon, .editIcon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dropArea {
  width: 10px;
  height: 102px;
  background: grey;
}

.test {
  border: 1px solid red;
}
