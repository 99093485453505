.ggTtke {
  display: none !important;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --maxwidth: 1366px;

  --header-height: 64px

  --color-primary: #0176c6;
  --color-light-gray: #f3f3f6;
  --color-gray-text: #737381;
  --color-black-text: #1E1E24;
  --color-accent-text: #0063f7;
  --color-disabled-text: #a0a0ad;
  --color-red-text: #FF3B3B;
  --color-green-text: #06C270;

  --font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  --h1-font: 600 24px/32px var(--font-family);
  --h2-font: 600 20px/32px var(--font-family);
  --h3-font: 600 16px/24px var(--font-family);
  --h4-font: 500 15px/20px var(--font-family);
  --h5-font: 500 12px/20px var(--font-family);
  --caption-font: 400 14px/20px var(--font-family);
  --body-font: 400 15px/24px var(--font-family);
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  overflow-y: scroll;
}

h1 {
  font: var(--h1-font);
}

h2 {
  font: var(--h2-font);
}

h3 {
  font: var(--h3-font);
}

h4 {
  font: var(--h4-font);
}

h5 {
  font: var(--h5-font);
}

.layout {
  min-height: 100vh;
  background: #fbfbfb;
}

.layout-content {
  width: 100%;
  max-width: var(--maxwidth);
  padding: 20px 24px;
  margin: 64px auto 0;
  background: white;
  min-height: calc(100vh - var(--header-height));
}

@media (min-width: 1366px) {
  .layout-content {
    padding: 32px 40px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  }
}

.footer {
  padding: 10px 15px;
}

.footerContent {
  width: 100%;
  height: 100%;
  max-width: var(--maxwidth);
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
