.page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form {
  width: 100%;
  max-width: 768px;
}

.collapsePanel :global(.ant-collapse-content-box) {
  padding: 0;
}
