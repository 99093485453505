.formValue {
  display: block;
  height: 30px;
  padding: 0;
  /*border: 1px solid rgb(217 217 217);*/
  border-radius: 2px;
  /* background: white; */
  line-height: 30px;
  border: 0;
}

@media (min-width:1096px) {
  .formValue {
  padding: 0 11px;
  }
  
}
