.main {
  padding-top: 64px;
}

.botBlock {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  align-items: center;

  position: fixed;
  right: 40px;
  bottom: 40px;
}

/*:global(.ant-layout-footer) {*/
/*  display: none;*/
/*}*/

/*:global body {*/
/*  overflow-y: unset !important;*/
/*}*/
