.container {
  width: calc(100% + 48px);
  display: flex;
  flex-direction: column;
  margin: -20px -24px;
  /*background: linear-gradient(40deg, #dadef1 0%, #fff5ef 50%) fixed !important;*/
}

@media (min-width: 1366px) {
  .container {
    width: calc(100% + 80px);
    margin: -32px -40px;
  }
}

.container > :nth-child(n) {
  max-width: 1024px;
  padding: 20px 24px;
}

@media (min-width: 1024px) {
  .container > :nth-child(n) {
    padding: 32px 40px;
  }
}

.title {
  margin: 0 auto;
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 700;
  font-size: 30px;
}

@media (min-width: 768px) {
  .title {
    font-size: 34px;
  }
}

@media (min-width: 1024px) {
  .title {
    font-size: 40px;
  }
}
