.formRow {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.formLabel {
  height: 32px;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  display: block;
  width: 100px;
}
