/*@import '~antd/dist/antd.css';*/
@import '~antd/dist/antd.variable.min.css';
/*@import '~antd/dist/antd.compact.min.css';*/
/*@import '~antd/dist/antd.';*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
}

p {
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.highcharts-container {
  width: 100% !important;
}

/*.dropdownHeightFix  {*/
/*  border: 2px solid red;*/
/*}*/

/*.dropdownHeightFix .ant-select-tree-list-holder {*/
/*  max-height: 1000px !important;*/
/*}*/

.bigForm .ant-form-item-label label {
  overflow: hidden;
  width: 80px;
  margin-left: 10px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-layout-header {
  padding-left: 0;
}

.highcharts-credits {
  display: none;
  opacity: 0;
}

.react-ip-input {
  min-width: 150px;
}

.zeroPadding .ant-collapse-content-box {
  padding: 0 !important;
}

.zeroPaddingHeader .ant-collapse-header {
  /* padding-left: 0 !important; */
  padding-right: 0 !important;
}

.mainContainer {
  padding: 10px 10px 0;
}

@media screen and (min-width: 1600px) {
  .mainContainer {
    padding: 10px 50px 0;
  }
}

@media screen and (max-width: 1600px) {
  .ant-layout-header {
    padding-right: 10px;
  }
}

.ant-page-header-compact {
  padding: 10px;
}

.site-page-header {
  margin-left: 36px;
}

.site-page-header>.ant-page-header-heading>.ant-page-header-heading-left>.ant-page-header-heading-title {
  text-overflow: unset;
}

@media screen and (max-width: 450px) {
  .site-page-header>.ant-page-header-heading>.ant-page-header-heading-left>.ant-page-header-heading-sub-title {
    display: none;
  }
}

.highlighted {
  background: #1890ff !important;
  color: #fff !important;
}

.highlighted td {
  background: #1890ff !important;
  color: #fff !important;
}

.settingsAside {
  /* position:sticky; */
  position: fixed;
  left: 0;
  z-index: 1;
  flex: 0 0 200px;

  /* overflow: auto; */
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  height: 100vh;
}

.settingsAside,
.settingsAside>.ant-layout-sider-trigger,
.settingsAside>.ant-layout-sider-children>.ant-menu,
.settingsAside>.ant-layout-sider-children>.ant-menu>.ant-menu-item,
.settingsAside>.ant-layout-sider-children>.ant-menu>.ant-menu-item>span,
.settingsAside>.ant-layout-sider-children>.ant-menu>.ant-menu-item>span>a,
.settingsAside>.ant-layout-sider-children>.ant-menu>.ant-menu-item::after,
.settingsAside>.ant-layout-sider-children>.ant-menu>.ant-menu-item>.anticon {
  -webkit-transition: none;
  transition: none;
}

.publicRow {
  background-color: rgba(236, 236, 173, 0.25);
}

.ant-table-tbody>.publicRow.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>.publicRow:hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>.publicRow:hover:not(.ant-table-expanded-row)>td {
  background-color: rgba(236, 236, 173, 0.1);
}

.publicRow>.ant-table-column-sort {
  background-color: rgba(255, 255, 255, 0.4);
}

.rowHighlighted {
  /* background-color: rgba(0, 0, 255, 0.16); */
  background-color: rgb(197 227 255) !important;
}

.rowHighlighted td {
  background-color: rgb(197 227 255) !important;
}

.ant-table-tbody>.rowHighlighted.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>.rowHighlighted:hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>.rowHighlighted:hover:not(.ant-table-expanded-row)>td {
  background-color: rgba(0, 0, 255, 0.07);
}

.rowHighlighted>.ant-table-column-sort {
  background-color: rgba(255, 255, 255, 0.4);
}

.rowHighlighted>td,
.defaultStudyRow>td,
.publicRow>td {
  padding: 1px 5px !important;
}

.ant-menu-item:first-child {
  margin-top: 0 !important;
}

.compressDrawer>.ant-drawer-mask {
  opacity: 0.1 !important;
}

.site-form-item-icon {
  color: rgba(0, 0, 0, 0.25);
}

.ant-message {
  z-index: 10000000000;
}

.ant-layout-footer {
  position: relative;
}

.ant-input-number-input,
.ant-input {
  font-size: 16px;
}

.analytic-slider .ant-slider-rail {
  background-color: #d9d9d9;
}

.ant-dropdown {
  z-index: 1051;
}