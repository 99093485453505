.analyticFilter {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
}

.addNewFilterConfigBtn {
  margin-left: auto;
}
