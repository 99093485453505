.charts {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
}

.sectionTitle {
  margin-bottom: 10px;
  margin-top: 10px;
}


@media screen and (min-width: 800px) {
  .charts {
    grid-template-columns: 1fr 1fr;
  }
}

/*@media screen and (min-width: 1400px) {*/
/*  .charts {*/
/*    grid-template-columns: 1fr 1fr 1fr;*/
/*  }*/
/*}*/
