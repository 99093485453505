.archivedContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  border: 1px solid #b1afaf;
  border-radius: 4px;
  cursor: pointer;

  & > p {
    font-size: 16px;
    font-weight: 700;
  }
}

.openFolder {
  font-size: 18px;
}
